import * as React from 'react'
import { navigate } from 'gatsby'
import { Helmet } from 'react-helmet'
import Layout from '../../layouts/Layout'
import auther from '../../helpers/auth'
import Hero from '../../components/Hero/Hero'
import heroImageMap from '../../components/MapSection/LargeMap.png'
import { hero } from './login.module.scss'

const auth = auther()

const LoginResponse = () => {
  const handleAuth = async() => {
    try {
      await auth.handleAuthentication()
      // Hard load page so js reloaded with token
      window.location = '/portal'
    } catch (e) {
      if (e.errorDescription === 'not-activated') {
        navigate('/login/pending')
        return
      }
      console.log(e)
      alert('Error!')
      navigate('/about')
    }
  }
  React.useEffect(() => {
    handleAuth()
  }, [])
  return (
    <Layout getInTouchVisible={false} blueFooter={true} login={true}>
      <Helmet>
        <title>Login - Emso</title>
      </Helmet>
      <Hero header={'Login'} image={heroImageMap} className={hero} content={[
        'Please wait..',
      ]}>
      </Hero>
    </Layout>
  )
}

export default LoginResponse;
